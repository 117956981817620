import { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

import './start.css';
import { routes } from '../conf';
import { PageLayout } from '../components';
import { GlobalContext } from '../App';
import { defaultUserInformationsState } from '../appState/default-user-information-state';

export const StartPage = () => {
  const { setUserInformations } = useContext(GlobalContext);
  const localStorageToken = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorageToken) {
      navigate(routes.chooseSpeedCarPlace);
    }
    setUserInformations(defaultUserInformationsState);
  }, []);

  return (
    <>
      <PageLayout title="" withLogo={true} homepage={true}>
        <div className="home-page-button-container">
          <Link to={routes.chooseSpeedCarWorker} state={{ redirectTo: routes.inspectionInitPage }}>
            <Button className="home-page-button">Wykonaj przegląd</Button>
          </Link>
        </div>
        <div className="home-page-button-container">
          <Link to={routes.scanRodoRequest}>
            <Button className="home-page-button">Skanuj wniosek RODO</Button>
          </Link>
        </div>
        <div className="home-page-button-container">
          <Link to={routes.chooseSpeedCarWorker} state={{ redirectTo: routes.insuranceInitPage }}>
            <Button className="home-page-button">Ubezpieczenia bez przeglądu</Button>
          </Link>
        </div>
        <div className="home-page-button-container">
          <Link to={routes.chooseSpeedCarWorker} state={{ redirectTo: routes.policyDocumentationInitPage}}>
            <Button className="home-page-button">Wydaj polisę</Button>
          </Link>
        </div>
        <p className='version-text'>v 1.1.0</p>       
      </PageLayout>
    </>
  );
};
